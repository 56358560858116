import React, { useState, useEffect } from "react"; // Importing React and its hooks.
import "./PocsList.scss"; // Importing custom styles for the Pocs List.
import Table from "src/components/shared/Table"; // Importing a Table component to display the pocss.
import SearchBox from "src/components/shared/SearchBox"; // SearchBox component for filtering pocs.
import NoSearchResultFound from "src/components/ui/NoSearchResultFound"; // Component to show if no search results are found.
import { IPoc } from "src/state/pocs/reducer"; // Importing the Poc interface.
import { RootState } from "src/state/rootReducer"; // Importing the RootState type.
import { useSelector, useDispatch } from "react-redux"; // Hooks to access Redux state and dispatch actions.
import { getPocs } from "src/state/pocs/actions"; // Redux action to fetch pocs.
import { getPocsColumns } from "src/containers/admin/pocs/PocsColumns"; // Column configuration for the table.
// import AccessList from "./AccessList"; // Component to display access details for a poc.
// import { enableLab, deleteLabById } from "src/api/lab"; // API methods for enabling and deleting pocs.
import WarningModal from "src/components/shared/WarningModal"; // Modal to confirm lab deletion.
import { Button } from "react-bootstrap"; // Bootstrap Button component.
import { useHistory } from "react-router-dom"; // Hook to programmatically navigate between routes.
import { ADMIN_ROUTE, ADMIN_NEW_POC_ROUTE, ADMIN_EDIT_POC_ROUTE, ADMIN_DETAILS_POC_ROUTE } from "src/constants/appRoutes"; // Route constants.
import { ReactComponent as AddIcon } from "src/assets/images/add.svg"; // Importing an SVG as a React component.
import Select from 'react-select';
import { IUser } from "src/state/users/reducer";
import { userTypesMap } from "src/constants/users";
import { usersAction } from "src/state/users/actions";
import AddOrEditPoc from "src/components/AddOrEditPoc/AddOrEditPoc";

type PocsListProps = {}; // Defining the props type for the PocsList component.

const PocsList: React.FC<PocsListProps> = (props) => {


  const nonClosedStatuses = [
    'New',
    'Pre-Deployment',
    'Deployment',
    'Active'
  ];

  const closedStatuses = [
    'Cancelled',
    'Incomplete',
    'Complete'
  ];

  // State hooks for managing search query, filtered pocs, access list details, modal visibility, etc.
  const [searchBy, setSearchBy] = useState(""); // Search query.
  const [filteredPocs, setFilteredPocs] = useState<IPoc[]>([]); // Filtered list of pocs.
  // const [accessListDetails, setAccessListDetails] = useState({}); // Details for the access list modal.
  // const [displayAccessList, setDisplayAccessList] = useState(false); // Flag to show/hide access list modal.
  const [showDeleteWarningModal, setShowDeleteWarningModal] = useState(false); // Flag to show/hide delete warning modal.
  const [selectedPocId, setSelectedPocId] = useState(""); // ID of the poc selected for deletion.
  const [showAddorEditPocModal, setShowAddorEditPocModal] = useState(false)
  const [isEditMode, setIsEditMode] = useState(false);
  const [selectedPocData, setSelectedPocData] = useState<IPoc>();
  let history = useHistory(); // History hook to navigate routes.
  const dispatch = useDispatch(); // Dispatch hook to dispatch Redux actions.
  const pocs: IPoc[] = useSelector((state: RootState) => state.pocs.list); // Accessing pocs from the Redux store.
  const loaderCount = useSelector((state: RootState) => state.ui.loaderCount); // Checking the loader count to show/hide loading states.
  const user: IUser = useSelector((state: RootState) => state.user)
  const isLoading = loaderCount > 0; // Boolean flag to indicate loading state.
  const [isFiltering, setIsFiltering] = useState(true);
  const [selectedStatus, setSelectedStatus] = useState([]);
  const corporateUserList: Array<any> = useSelector(
    (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));

  const statusOptions = [
    ...nonClosedStatuses.map((status) => ({ label: status, value: status.toLowerCase() })),
    ...closedStatuses.map((status) => ({ label: status, value: status.toLowerCase() })),
  ];

  useEffect(() => {
    dispatch(getPocs()); // Fetch pocs when the component mounts.
    if (corporateUserList.length === 0) {
      dispatch(usersAction());
    }
  }, []);

  // // Function to show the access list (assigned users, groups, companies) for a lab.
  // const showAccessList = (row) => {
  //   const data = row.original; // Accessing the lab data from the row.
  //   let { assignedUsers, assignedGroups, assignedCompanies } = data; // Extracting related entities.
  //   setAccessListDetails({
  //     assignedUsers,
  //     assignedGroups,
  //     assignedCompanies,
  //     labName: data.name,
  //   });
  //   setDisplayAccessList(true); // Displaying the access list modal.
  // };

  // Function to activate/deactivate a poc using the API.
  const activatePoc = async (poc, flag) => {
    const { pocId, oppId } = poc;
    const payload = {
      is_active: flag, // Only sending the active flag for activation/deactivation.
    };

    // let response = await enableLab(payload, id); // Enabling/disabling the poc via API.

    // if (response && !response.isAxiosError) {
    //   dispatch(getPocs()); // Refresh labs after the operation.
    // }
    console.log("This is a place holder only, enable/disable POC action is not supported");
  };

  // Helper function to close the delete confirmation modal.
  const closeDeleteGroupModal = () => {
    setSelectedPocId(""); // Clear the selected poc ID.
    setShowDeleteWarningModal(false); // Hide the modal.
  };

  // Function to show the delete confirmation modal for a specific poc.
  const displayDeletePocModal = (poc) => {
    setSelectedPocId(poc.pocId); // Set the selected poc ID for deletion.
    setShowDeleteWarningModal(true); // Show the delete confirmation modal.
  };

  // Function to delete a poc using the API.
  const deletePoc = async () => {
    // const data = await deleteLabById(selectedLabId); // Deleting the lab via API.
    // if (data) {
    //   dispatch(getPocs()); // Refresh labs after deletion.
    //   closeDeleteGroupModal(); // Close the delete modal. 
    // }
    console.log("This is a place holder only, delete POC action is not supported");
    closeDeleteGroupModal(); // Close the delete modal.
  };

  const toDetailsPoc = (pocId) => {
    history.push(`${ADMIN_ROUTE}${ADMIN_DETAILS_POC_ROUTE}/${pocId}`); // Redirect to edit poc route.
  }
  // Function to the create new poc page.
  const createPoc = () => {
    setIsEditMode(false);
    setShowAddorEditPocModal(true);
  };

  // Function to the create new poc page.
  const handleEditPoc = (pocId) => {
    setIsEditMode(true);
    let selectedPoc = filteredPocs.find((item) => item.pocId == pocId)
    let userData = corporateUserList.find((item) => item.id == selectedPoc?.accountSe);
    //@ts-ignore
    selectedPoc = { ...selectedPoc, se_user: userData }
    setSelectedPocData(selectedPoc)
    setShowAddorEditPocModal(true)
  }

  // console.log('user', user)
  // Configuring the columns for the table displaying the pocs.
  const pocsColumns = getPocsColumns(
    toDetailsPoc,
    handleEditPoc
  );
  // console.log('pocsColumns', pocsColumns)

  const filterPocList = (searchQuery) => {
    let filteredPocs = pocs.filter((poc) => {
      if (!searchQuery) return true; // If no search query, return all pocs.
      const pocId = poc.pocId && poc.company.toString().toLowerCase();
      const oppId = poc.oppId && poc.oppId.toString().toLowerCase();
      const query = searchQuery && searchQuery.toLowerCase();
      return (pocId && pocId.includes(query)) || (oppId && oppId.includes(query)); // Match name or tags with the search query.
    });

    setIsFiltering(true); // Set filtering state to true before filtering starts
    setFilteredPocs(filteredPocs); // Update the filtered pocs list.
    setIsFiltering(false); // Set filtering state to false after updating
    // console.log('filteredPocs', filteredPocs)
  };

  // Update filtered pocs whenever the pocs list changes.
  useEffect(() => {
    filterPocList(searchBy); // Filter based on the current search query.
  }, [pocs, searchBy]);

  // Handler to update the search query when the user types in the search box.
  const handleSearchByChange = (updatedSearchQuery) => {
    setSearchBy(updatedSearchQuery); // Update the search query state.
    filterPocList(updatedSearchQuery); // Filter the pocs list based on the new query.
  };



  const filterPocListByStatus = (selectedStatuses) => {
    // Convert selectedStatuses to a Set of lowercase values for efficient lookup
    console.log("selectedStatuses:", selectedStatuses)

    const selectedStatusValues = selectedStatuses == null ? [] : selectedStatuses.map((status) => status.value.toLowerCase());
    console.log("selectedStatusValues:", selectedStatusValues)

    const filteredPocsByStatus = pocs.filter((poc) => {
      if (!selectedStatusValues.length) return true; // Return all if no selected statuses

      // Convert each poc status to lowercase and check if it's in the selected set
      const status = poc.status && poc.status.toString();
      console.log("poc status:", status)

      return selectedStatusValues.includes(status);
    });
    console.log("filteredPocsByStatus:", filteredPocsByStatus)
    setIsFiltering(true); // Set filtering state to true before filtering starts
    setFilteredPocs(filteredPocsByStatus); // Update the filtered pocs list.
    setIsFiltering(false); // Set filtering state to false after updating
  };

  // Update filtered pocs whenever the pocs list changes.
  useEffect(() => {
    filterPocListByStatus(selectedStatus); // Filter based on the current search query.
  }, [pocs, selectedStatus]);

  // const statusOptions = [
  //   ...nonClosedStatuses, ...closedStatuses
  // ];

  // Handler for toggling selection of a status
  // const handleChangeStatus = (event) => {
  //   const { value, checked } = event.target;
  //   setSelectedStatus((prevValues) => checked ? [...prevValues, value] : prevValues.filter((item) => item !== value));
  //   filterPocListByStatus(value)
  // }  
  return (
    <div className="pocs-list-section d-flex flex-column flex-grow-1">
      {showDeleteWarningModal && (
        <WarningModal
          title="Warning"
          onCancelAction={closeDeleteGroupModal}
          onConfirmAction={deletePoc}
        >
          <div className="ml-2">
            <div>Delete POC action is not supported currently</div>
            <div>
              <b>{selectedPocId}</b> ?
            </div>
          </div>
        </WarningModal>
      )}
      {showAddorEditPocModal && (
        <AddOrEditPoc
          isEditMode={isEditMode}
          selectedPoc={selectedPocData}
          setShowAddorEditPocModal={setShowAddorEditPocModal}
        />
      )}
      <div className="d-flex align-items-center justify-content-between mb-2">
        <div className={`pocs-list-section-header`}>
          <span>
            <b>Hosted POCs</b>
          </span>
        </div>
        {/* <div>
          <div className="multi-select-dropdown">
            {/* Multi-select dropdown for selecting statuses */}
        {/*<label>Filter by POC Status:</label>
            <Select
              options={statusOptions} // Status options for filtering
              isMulti // Enable multi-selection
              value={selectedStatus} // Current selected statuses
              onChange={setSelectedStatus} // Update selected statuses 
              placeholder="Select statuses..."
            />
          </div>
        </div> */}
        <div className="d-flex align-self-center">
          <div className="pocs-list-section-search d-flex align-items-center">
            <SearchBox
              updateSearchQuery={handleSearchByChange}
              placeholder="Search by Company or OppId"
            />
          </div>
          {filteredPocs.length ? (
            <Button
              type="submit"
              size="sm"
              className="ml-2"
              variant="primary"
              onClick={createPoc}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> New POC </span>
              </div>
            </Button>
          ) : null}
        </div>
      </div>

      {/* Conditional rendering based on filtering status */}
      {isFiltering ? (
        <div>Loading...</div> // Show loading indicator while filtering
      ) : filteredPocs.length ? (
        <Table searchBy={searchBy} columns={pocsColumns} data={filteredPocs} />
      ) : !!searchBy ? (
        <NoSearchResultFound
          message="Your search was not successful!"
          detail="Try some relevant company or OppId"
          errImgSrc="no-users.svg"
        />
      ) : (
        !isLoading && (
          <>
            <NoSearchResultFound
              message="No Pocs Created!"
              detail="Click below to create a new poc"
              errImgSrc="no-lab.svg"
            />
            <Button
              type="submit"
              size="sm"
              className="ml-2 no-result-add-btn"
              variant="primary"
              onClick={createPoc}
            >
              <div className="d-flex align-items-center pl-2 pr-2">
                <AddIcon height="10" width="10" />
                <span className="ml-2"> Create POC </span>
              </div>
            </Button>
          </>
        )
      )}
    </div>
  );
};

export default PocsList;


// import React, { useState, useEffect } from 'react';
// import Select from 'react-select';

// // Combine statuses into a single array of options for the dropdown
// const statusOptions = [
//   ...nonClosedStatuses.map((status) => ({ label: status, value: status })),
//   ...closedStatuses.map((status) => ({ label: status, value: status })),
// ];

// const PocsList = ({ pocs, nonClosedStatuses, closedStatuses }) => {
//   const [isFiltering, setIsFiltering] = useState(true);
//   const [selectedStatus, setSelectedStatus] = useState([]); // Array to hold selected statuses
//   const [filteredPocs, setFilteredPocs] = useState([]);

//   // Function to filter POCs based on selected statuses
//   const filterPocListByStatus = (selectedStatuses) => {
//     const selectedStatusValues = selectedStatuses.map((status) => status.value);
//     const filteredPocsByStatus = pocs.filter((poc) => {
//       if (!selectedStatusValues.length) return true; // Return all if no statuses selected
//       const status = poc.status && poc.status.toString();
//       return selectedStatusValues.includes(status); // Only keep POCs with a matching status
//     });

//     setIsFiltering(true); // Indicate that filtering is happening
//     setFilteredPocs(filteredPocsByStatus); // Update filtered list based on selected statuses
//     setIsFiltering(false); // Filtering is complete
//   };

//   // Use effect to filter whenever `selectedStatus` changes
//   useEffect(() => {
//     filterPocListByStatus(selectedStatus);
//   }, [selectedStatus, pocs]);

//   return (
//     <div>
//       {/* Multi-select dropdown for selecting statuses */}
//       <Select
//         options={statusOptions} // Status options for filtering
//         isMulti // Enable multi-selection
//         value={selectedStatus} // Current selected statuses
//         onChange={setSelectedStatus} // Update selected statuses
//         placeholder="Select statuses..."
//       />

//       {/* Render filtered POCs in a Table component, or show a message if none are found */}
//       {filteredPocs.length ? (
//         <Table data={filteredPocs} />
//       ) : (
//         <p>No matching POCs found.</p>
//       )}
//     </div>
//   );
// };

// export default PocsList;
