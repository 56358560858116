import React, { useEffect, useRef, useState } from "react";
import PureModal from "../shared/PureModal";
import "./AddOrEditPoc.scss";
import { Form, Button } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import CustomTooltip from "../ui/CustomTooltip";
import RequiredFieldSymbol from "../ui/RequiredFieldSymbol";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getPayload, mapPodValues } from "src/helpers/pocs";
import { createAdminPoc, partiallyUpdatePoc, saveAdminPoc } from "src/api/poc";
import { useHistory, useParams, Link } from "react-router-dom";
import InfoIcon from "src/assets/images/info-icon.svg";
import InputErrorMessage from "../shared/InputErrorMessage";
import Select from "react-select";
import { userTypesMap } from "src/constants/users";
import { IPoc } from "src/state/pocs/reducer";
import { ISettings } from "src/state/settings/reducer";
import DTPicker from "react-datepicker";
import { getPocs } from "src/state/pocs/actions";

type AddOrEditPocProps = {
    setShowAddorEditPocModal: (boolean) => void;
    isEditMode: boolean;
    type?: number,
    selectedPoc?: any
};

const newPoc = {
    pocId: "",
    status: "New",
    deployedBy: "",
    company: "",
    accountSe: "",
    accountAe: "",
    oppId: "",
    labProviderType: "VSA",
    requestedStart: "",
    actualStart: "",
    endDate: "",
    podNumber: -1,
    testPlan: "",
    theatre: "",
    stage: "",
    accessList: [] as any,
    voucherConfigList: [] as any,
};

const theatreDropdownList = [{
    id: 1,
    name: 'AMER'
},
{
    id: 2,
    name: 'LATAM'
},
{
    id: 3,
    name: 'EMEA'
},
{
    id: 4,
    name: 'APJ'
},
{
    id: 5,
    name: 'WW/Other'
}];

const stageDropdownList = [{
    id: 1,
    name: '1 - Qualifying'
},
{
    id: 2,
    name: '2 - Validating Solution'
},
{
    id: 3,
    name: '3 - Proposing'
},
{
    id: 4,
    name: '4 - Negotiating'
},
{
    id: 5,
    name: '8 - Closed/Won'
},
{
    id: 6,
    name: '8 - Closed/Disqualified'
},]

const statusOption = {
    new: ['New', 'Pre-Deployment', 'Canceled'],
    predeployment: ['Pre-Deployment', 'Deployment', 'Incomplete'],
    deployment: ['Deployment', 'Active', 'Incomplete'],
    active: ['Active', 'Incomplete', 'Complete'],
    complete: ['Complete'],
    incomplete: ['Incomplete'],
    canceled: ['Canceled']

}
const AddOrEditPoc: React.FunctionComponent<AddOrEditPocProps> = ({
    isEditMode,
    ...props
}) => {
    const history = useHistory();
    const dispatch = useDispatch();

    const buttonRef = useRef<HTMLImageElement | null>(null);

    const { pocId }: { pocId } = useParams();
    const initialValues = isEditMode ? props.selectedPoc : newPoc;
    const [podValues, setPodValues] = useState([]);
    const [modalLoaded, setModalLoaded] = useState(false);

    const user = useSelector((state: RootState) => state.user);
    const pocs: IPoc[] = useSelector((state: RootState) => state.pocs.list);
    const settings: ISettings = useSelector((state: RootState) => state.settings);
    const corporateUserList: Array<any> = useSelector(
        (state: RootState) => (state.users.list).filter((user) => user.type == userTypesMap.corporate));

    //validations necessary for the form fields
    const validationSchema = Yup.object({
        company: Yup.string().required("Company name is required"),
        oppId: Yup.string().required("Oppurtunity Id is required"),
        accountSe: Yup.string().required('Account SE is required'),
        accountAe: Yup.string().required("Account AE is required"),
        theatre: Yup.string().required("Theatre is required"),
        stage: Yup.string().required("Stage is required"),
        status: Yup.string().required("Status is required"),
        podNumber: Yup.string().required("POD Number is required"),
        requestedStart: Yup.string().required("Requested Start Date is required"),
        actualStart: Yup.string().nullable().when("status", {
            is: (status) => { return status !== initialValues.status },
            //is: "Active", // If status is "Active"
            then: Yup.string().required("Effective date is required"),
            otherwise: Yup.string().notRequired(), // If status is not "Active", no validation
        }),
    });

    useEffect(() => {
        if (pocs.length != 0 && !isEditMode) {
            const getPodValues = async () => {
                let pod_values = await mapPodValues(settings, pocs);
                setPodValues(pod_values)
                initialValues.podNumber = pod_values[0];
                setModalLoaded(true)
            }
            getPodValues()
        }
        //Edit Mode
        else {
            const getPodValues = async () => {
                let pod_values = await mapPodValues(settings, pocs, initialValues.podNumber);
                setPodValues(pod_values)
                setModalLoaded(true)
            }
            getPodValues();
        }
    }, [])

    const handleStatusChange = (formik, event) => {
        const { value } = event.target;
        formik.setFieldValue('status', value); // Update the status value
        formik.setFieldValue('actualStart', value === initialValues.status ? initialValues.actualStart : ''); // Reset startDate when status changes

    }

    const onSubmitHandle = async (poc) => {
        let response;

        if (isEditMode) {
            const payload = getPayload(poc, isEditMode, props.type);
            payload.is_status_update = poc.status === initialValues.status ? false : true;
            response = await partiallyUpdatePoc(payload, poc.pocId);
        } else {
            poc.deployedBy = user.id
            const payload = getPayload(poc);
            response = await createAdminPoc(payload);
        }
        if (response) {
            props.setShowAddorEditPocModal(false);
            dispatch(getPocs());
        }
    };
    const getStatusOptions = () => {
        return statusOption[(initialValues.status).toLowerCase().replace('-', '')];
    }


    return <>
        {modalLoaded &&
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={onSubmitHandle}
                enableReinitialize
            >
                {(formik) => (
                    <PureModal
                        title={isEditMode ? "Edit POC" : "Add POC"}
                        showModal={true}
                        size="lg"
                        closeModal={() => props.setShowAddorEditPocModal(false)}
                        primaryBtnLabel={isEditMode ? "Save" : "Add"}
                        primaryBtnAction={formik.handleSubmit}
                        secondaryBtnLabel={"Cancel"}
                        secondaryBtnAction={() => props.setShowAddorEditPocModal(false)}
                    >

                        <Form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                        >
                            <Form >
                                <div className="poc-container">
                                    <div className="poc-form-wrapper">
                                        {(!props.type || props.type == 1) &&
                                            <>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>
                                                        Company
                                                        <CustomTooltip text="Enter name of company the SE is requesting POC for">
                                                            <img
                                                                height="15"
                                                                width="15"
                                                                src={InfoIcon}
                                                                alt="InfoIcon"
                                                                className="ml-1"
                                                            />
                                                        </CustomTooltip>
                                                        <RequiredFieldSymbol />
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="text"
                                                            id="company"
                                                            {...formik.getFieldProps("company")}
                                                        />
                                                        {formik.touched.company && formik.errors.company && (
                                                            <InputErrorMessage
                                                                className={"error-message ml-1"}
                                                                errorMessage={formik.errors.company || ""}
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Opportunity ID <RequiredFieldSymbol /></Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="text"
                                                            id="oppId"
                                                            {...formik.getFieldProps("oppId")} />
                                                        {formik.touched.oppId && formik.errors.oppId && (
                                                            <InputErrorMessage
                                                                className={"error-message ml-1"}
                                                                errorMessage={formik.errors.oppId || ""}
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-4">
                                                    <Form.Label >
                                                        Account SE
                                                        <RequiredFieldSymbol />
                                                    </Form.Label>
                                                    <div>
                                                        <Select
                                                            // isDisabled={isEditMode}
                                                            id='accountSe'
                                                            className="select-nameEmail"
                                                            size={5}
                                                            placeholder="Search name or email"
                                                            defaultValue={{
                                                                value: formik.values.accountSe,
                                                                label: `${isEditMode ? formik.values.se_user.name + ',' + formik.values.se_user.email : formik.values.accountSe} `,
                                                            }}
                                                            isClearable={true}
                                                            onChange={(data) => {
                                                                formik.setFieldTouched("accountSe");
                                                                formik.setFieldValue(
                                                                    "accountSe",
                                                                    data && data.value ? data.value : ""
                                                                )
                                                            }}
                                                            options={corporateUserList.map((resource) => {
                                                                return {
                                                                    value: resource.id,
                                                                    label: `${resource.name}, ${resource.email}`

                                                                };
                                                            })}
                                                        />
                                                        {formik.touched.accountSe && formik.errors.accountSe && (
                                                            <InputErrorMessage
                                                                className={"error-message"}
                                                                errorMessage={formik.errors.accountSe || ""}
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Account AE <RequiredFieldSymbol /></Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="text"
                                                            id="accountAe"
                                                            {...formik.getFieldProps("accountAe")}
                                                        />
                                                        {formik.touched.accountAe && formik.errors.accountAe && (
                                                            <InputErrorMessage
                                                                className={"error-message ml-1"}
                                                                errorMessage={formik.errors.accountAe || ""}
                                                            />
                                                        )}
                                                    </div>

                                                </Form.Group>
                                                <Form.Group className="mb-4">
                                                    <Form.Label >
                                                        Theatre
                                                        <RequiredFieldSymbol />
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="select"
                                                            as="select"
                                                            id="theatre"
                                                            {...formik.getFieldProps("theatre")}
                                                            isInvalid={!!(formik.touched.theatre && formik.errors.theatre)}
                                                        >
                                                            {[
                                                                <option value="">Select</option>,
                                                                ...theatreDropdownList.map((theatre) => (
                                                                    <option value={theatre.name}>
                                                                        {(theatre.name)}
                                                                    </option>
                                                                )),
                                                            ]}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            <InputErrorMessage errorMessage={formik.errors.theatre || ""} />
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group className="mb-4">
                                                    <Form.Label >
                                                        Stage
                                                        <RequiredFieldSymbol />
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="select"
                                                            as="select"
                                                            id="stage"
                                                            {...formik.getFieldProps("stage")}
                                                            isInvalid={!!(formik.touched.stage && formik.errors.stage)}
                                                        >
                                                            {[
                                                                <option value="">Select</option>,
                                                                ...stageDropdownList.map((stage) => (
                                                                    <option value={stage.name}>
                                                                        {(stage.name)}
                                                                    </option>
                                                                )),
                                                            ]}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            <InputErrorMessage errorMessage={formik.errors.stage || ""} />
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                            </>
                                        }
                                        {(props.type == 2 || !props.type) &&
                                            <>

                                                <Form.Group className="mb-4">
                                                    <Form.Label >
                                                        POD Number
                                                        <RequiredFieldSymbol />
                                                    </Form.Label>
                                                    <div>
                                                        <Form.Control
                                                            type="select"
                                                            as="select"
                                                            id="podNumber"
                                                            {...formik.getFieldProps("podNumber")}
                                                            isInvalid={!!(formik.touched.podNumber && formik.errors.podNumber)}
                                                        >
                                                            {[
                                                                <option value="">Select</option>,
                                                                ...podValues.map((pod_num) => (
                                                                    <option value={pod_num}>
                                                                        {`POD - ${pod_num}`}
                                                                    </option>
                                                                )),
                                                            ]}
                                                        </Form.Control>
                                                        <Form.Control.Feedback type="invalid">
                                                            <InputErrorMessage errorMessage={formik.errors.podNumber || ""} />
                                                        </Form.Control.Feedback>
                                                    </div>
                                                </Form.Group>
                                                {(props.type == 2 || isEditMode) && <>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label >
                                                            Status
                                                            <RequiredFieldSymbol />
                                                        </Form.Label>
                                                        <div>
                                                            <Form.Control
                                                                type="select"
                                                                as="select"
                                                                id="status"
                                                                disabled={initialValues.status == 'Incomplete' || initialValues.status == 'Complete' || initialValues.status == 'Canceled'}
                                                                {...formik.getFieldProps("status")}
                                                                onChange={e => handleStatusChange(formik, e)}
                                                                isInvalid={!!(formik.touched.status && formik.errors.status)}
                                                            >
                                                                {[
                                                                    <option value="">Select</option>,
                                                                    ...(getStatusOptions()).map((status) => (
                                                                        <option value={status}>
                                                                            {(status)}
                                                                        </option>
                                                                    )),
                                                                ]}
                                                            </Form.Control>
                                                            <Form.Control.Feedback type="invalid">
                                                                <InputErrorMessage errorMessage={formik.errors.status || ""} />
                                                            </Form.Control.Feedback>
                                                        </div>
                                                    </Form.Group>
                                                    <Form.Group className="mb-4">
                                                        <Form.Label>Effective Date
                                                            {/* {formik.values.actualStart && */}
                                                            <CustomTooltip placement="top" text="Once saved, the effective date will no longer be editable for the current status.">
                                                                <img

                                                                    height="15"
                                                                    width="15"
                                                                    src={InfoIcon}
                                                                    alt="InfoIcon"
                                                                    className="ml-1"
                                                                    ref={buttonRef}
                                                                    tabIndex={0}

                                                                />
                                                            </CustomTooltip>
                                                            {/* } */}
                                                            <RequiredFieldSymbol /></Form.Label>
                                                        <div>
                                                            <Field name="actualStart">
                                                                {({ field }) => (
                                                                    <DTPicker
                                                                        {...field}
                                                                        disabled={initialValues.status == formik.values.status}
                                                                        selected={formik.values.actualStart ? new Date(formik.values.actualStart) : null}
                                                                        //minDate={new Date()}
                                                                        onChange={(date) => {
                                                                            formik.setFieldValue("actualStart", date.toLocaleDateString('en-US', {
                                                                                year: 'numeric',
                                                                                month: '2-digit',
                                                                                day: '2-digit',
                                                                            }))
                                                                            if (buttonRef.current && !buttonRef.current.getAttribute('attr-focus')) {
                                                                                buttonRef.current.focus();
                                                                                buttonRef.current.setAttribute('attr-focus', 'true');

                                                                            }
                                                                        }

                                                                        }
                                                                        showTimeSelect={false}
                                                                        // timeFormat="HH:mm"
                                                                        // timeIntervals={15}
                                                                        dateFormat="mm/dd/yyyy"
                                                                        className="date-picker-container form-control"
                                                                        placeholderText="Enter Effective Date"
                                                                    />
                                                                )}
                                                            </Field>
                                                            {formik.touched.actualStart && formik.errors.actualStart && initialValues.status != formik.values.status && (
                                                                <InputErrorMessage
                                                                    className={"error-message"}
                                                                    errorMessage={formik.errors.actualStart || ""}
                                                                />
                                                            )}
                                                        </div>
                                                    </Form.Group>
                                                </>}
                                                <Form.Group className="mb-4">
                                                    <Form.Label>Requested Start Date <RequiredFieldSymbol /></Form.Label>
                                                    <div>
                                                        <Field name="requestedStart">
                                                            {({ field }) => (
                                                                <DTPicker
                                                                    {...field}
                                                                    selectedDate={formik.values.requestedStart ? new Date(formik.values.requestedStart) : null}
                                                                    onChange={(date) =>
                                                                        formik.setFieldValue("requestedStart", date.toLocaleDateString('en-US', {
                                                                            year: 'numeric',
                                                                            month: '2-digit',
                                                                            day: '2-digit',
                                                                        }))
                                                                    }
                                                                    showTimeSelect={false}
                                                                    // timeFormat="HH:mm"
                                                                    // timeIntervals={15}
                                                                    dateFormat="mm/dd/yyyy"
                                                                    className="date-picker-container form-control"
                                                                    placeholderText="Enter Requested Start Date"
                                                                />
                                                            )}
                                                        </Field>
                                                        {formik.touched.requestedStart && formik.errors.requestedStart && (
                                                            <InputErrorMessage
                                                                className={"error-message"}
                                                                errorMessage={formik.errors.requestedStart || ""}
                                                            />
                                                        )}
                                                    </div>
                                                </Form.Group>
                                            </>
                                        }

                                    </div>
                                </div>
                            </Form>


                        </Form>

                    </PureModal>
                )}
            </Formik>
        }
    </>
}
export default AddOrEditPoc