import React, { useState, useEffect } from "react";
import "./PocDetails.scss";
import { Link, useParams } from "react-router-dom";
import rightArrow from "src/assets/images/right-arrow.svg";
import editPocIcon from "src/assets/images/poc-edit.svg"
import addVmIcon from "src/assets/images/add-consoleVM.svg"
import { IPoc } from "src/state/pocs/reducer";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "src/state/rootReducer";
import { getPocs } from "src/state/pocs/actions";
import { getPocDetails } from "src/api/poc";
import { getUsersbyId } from "src/api/users";
import { mapAdminPocs, mapPocDetails, mapPOCDetailsbyId } from "src/helpers/pocs";
import { formatDate } from "../PocsColumns";
import { capitalizeFirstLetter } from "src/helpers/common";
import AddOrEditPoc from "src/components/AddOrEditPoc/AddOrEditPoc";


const poc = {
    pocId: -1,
    createdOn: '',
    updatedOn: "",
    labProviderType: "",
    status: "",
    company: "",
    accountAe: "",
    oppId: "",
    requestedStart: null,
    actualStart: null,
    endDate: null,
    podNumber: -1,
    testPlan: null,
    theatre: "",
    stage: "",
    deployedBy: "",
    accountSe: "",
    activeDate: '',
    assignedUsers: [],
    assignedGroups: [],
    assignedCompanies: [],
    vcForUsers: [],
    vcForGroups: [],
    vcForCompanies: [],
    se_user: {
        id: "",
        email: "",
        name: "",
        company: null,
        type: "",
        userType: -1,
        role: "",
        userRole: -1,
        isActive: null,
        isInvited: null,
        labsLaunched: -1,
        lastLogin: "",
        status: ""
    }
}


const PocDetails: React.FC<any> = () => {

    const [pocData, setPocData] = useState(poc);
    const [showAddorEditPocModal, setShowAddorEditPocModal] = useState(false)
    const [editType, setEditType] = useState(0)
    const pocs: any = useSelector((state: RootState) => state.pocs.list);
    const userList: Array<any> = useSelector((state: RootState) => (state.users.list));

    const { pocId }: { pocId } = useParams();


    useEffect(() => {
        //on refersh Page
        if (pocs.length === 0) {
            const fetchPocbyId = async () => {
                let pocData = await getPocDetails(pocId);
                pocData = mapPOCDetailsbyId(pocData);
                pocData.se_user = await getUsersbyId(pocData.accountSe)
                pocData.activeDate = pocData.status_history.find(x => x.status == 'Active')?.effective_date
                setPocData(pocData);
            }
            fetchPocbyId()
        }
        //from poc listing page
        else {
            const fetchPocbyId = async () => {
                let pocData = pocs.find((item) => item.pocId == pocId);
                let userData = userList.find((item) => item.id == pocData?.accountSe) || await getUsersbyId(pocData.accountSe);
                pocData = { ...pocData, se_user: userData }
                pocData.activeDate = pocData.statusHistory.find(x => x.status == 'Active')?.effectiveDate
                setPocData(pocData)
            }
            fetchPocbyId()
        }
    }, [pocs])

    //on Edit Icon Click
    const handleEditPoc = (type) => {
        setEditType(type)
        setShowAddorEditPocModal(true)
    }

    const daysBetween = (dateString: string | null): number | string => {
        if (!dateString) {
            return ' - ';
        }
        // Parse the input date string into a Date object
        const inputDate: Date = new Date(dateString);

        // Get the current date
        const currentDate: Date = new Date();

        // Set the time of both dates to midnight to compare only the date part
        inputDate.setHours(0, 0, 0, 0);
        currentDate.setHours(0, 0, 0, 0);

        // Calculate the difference in time (in milliseconds)
        const timeDiff: number = currentDate.getTime() - inputDate.getTime();

        // Convert the time difference from milliseconds to days
        const daysDiff: number = timeDiff / (1000 * 3600 * 24);

        // Return the absolute value of the difference (to handle past or future dates)
        return Math.abs(Math.floor(daysDiff));
    }

    return <>
        {showAddorEditPocModal && (
            <AddOrEditPoc
                isEditMode={true}
                type={editType}
                selectedPoc={pocData}
                setShowAddorEditPocModal={setShowAddorEditPocModal}
            />
        )}

        <div className="px-4 tabs-layout">
            <div className={`pocs-list-section-header mb-3`}>
                <b>
                    <Link className="group-user-header" to="/admin/pocs">
                        POCs
                    </Link>
                </b>
                <img
                    height="15"
                    width="15"
                    src={rightArrow}
                    alt="InfoIcon"
                    className="mr-1 ml-1"
                />
                <span className="h4">Hosted POC Details</span>
            </div>
            {/* <span className="mb-2 tabs-layout-header"><b> Hosted POC Details </b></span> */}
            <div className="details_container">
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Opportunity Info" imageObj={{ img_url: editPocIcon, alt: 'Edit POC' }} handleEditPoc={() => handleEditPoc(1)} >
                            <InfoCapsules name="Company" value={pocData.company} />
                            <InfoCapsules name="Opportunity ID" value={pocData.oppId} />
                            <InfoCapsules name="Account SE" value={pocData.se_user.name} />
                            <InfoCapsules name="Account AE" value={pocData.accountAe} />
                            <InfoCapsules name="Theatre" value={pocData.theatre} />
                            <InfoCapsules name="Stage" value={pocData.stage} />
                        </InfoDetails>
                    </div>
                    <div>
                        <InfoDetails lable="General Info" imageObj={{ img_url: editPocIcon, alt: 'Edit POC' }} handleEditPoc={() => handleEditPoc(2)}>
                            <InfoCapsules name="Created On" value={pocData.createdOn && formatDate(pocData.createdOn)} />
                            <InfoCapsules name="Status" value={pocData.status} />
                            <InfoCapsules name="Requested Start" value={pocData.requestedStart && formatDate(pocData.requestedStart)} />
                            <InfoCapsules name="Actual Start" value={pocData.activeDate && formatDate(pocData.activeDate)} />
                            <InfoCapsules name="Days Active" value={daysBetween(pocData.activeDate)} />
                            <InfoCapsules name="Date Closed" value="" />
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3">
                    <div>
                        <InfoDetails lable="Deployed Console VMs" imageObj={{ img_url: addVmIcon, alt: 'Add Console VM' }}><div></div>
                        </InfoDetails>
                    </div>
                </div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Assigned Resources"> <InfoCapsules name="Assigned POD" value={pocData.podNumber} /></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Notes/Comments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Attachments"><div></div></InfoDetails></div>
                <div className="card border-light px-4 py-3"><InfoDetails lable="Activities"><div></div></InfoDetails></div>
            </div>
        </div>

    </>

}
export default PocDetails;

const InfoDetails = ({ lable, children, ...props }) => {
    return <>
        <label className='card_header'>{lable} </label>
        {props.imageObj &&
            <img className="float-right mt-1 cursor-pointer" title="" src={props.imageObj.img_url} alt={props.imageObj.alt} height="18" width="18" onClick={props.handleEditPoc}></img>
        }
        <ul className="details_tab">
            {children}
        </ul>
    </>
}
const InfoCapsules = ({ name, value }) => {
    return <>
        <li className="d-flex">
            <div>{name}</div>
            <div>{value}</div>
        </li>
    </>
}